import { Controller } from "stimulus";

export default class extends Controller {
  static  targets = ['icInput', 'existingIcNotification', 'dic', 'companyName', 'street', 'city', 'zip']

  connect() {
    console.log(this.hasIcInputTarget)
  }

  checkExistingIco() {
    const controller = this;

    $.ajax({
      // url: `/assembly_protocols/user_unpaired_devices.json`,
      url: `/users/check_existing_ico.json`,
      type: 'get',
      data: `ico=${this.icInputTarget.value}`,
      success: function (response) {
        if (response.used) {
          controller.existingIcNotificationTarget.textContent = response.notification
          controller.existingIcNotificationTarget.classList.remove('hidden')
        } else {
          controller.existingIcNotificationTarget.text = ''
          controller.existingIcNotificationTarget.classList.add('hidden')

          controller.loadAres(controller.icInputTarget.value)
        }
      }
    })
  }

  loadAres(ico) {
    const controller = this;

    $.ajax({
      url: '/ares.json',
      type: 'POST',
      data: `ico=${ico}`,
      success: function (response) {
        console.log(response)
        console.log(response.dic)
        controller.dicTarget.value = response.dic
        controller.companyNameTarget.value = response.company_name
        controller.streetTarget.value = response.street
        controller.cityTarget.value = response.city
        controller.zipTarget.value = response.zip
      }
    })
  }
}
