import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ['role', 'account']
  static values = { role: Number }

  initialize() {
    this.change()
    const controller = this
    window.addEventListener('turbolinks:load', () => {
      const select = $(this.roleTarget).select2({
        tags: true,
        tokenSeparators: [',', ' ']
      });
      select.on("change", function (e) {
        controller.change()
      });
    })
  }

  change() {
    if (this.roleValue == parseInt(this.roleTarget.value)){
      this.accountTarget.classList.remove('hidden')
    } else {
      this.accountTarget.classList.add('hidden')
    }
  }
}
