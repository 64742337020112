import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ['form']

  change(e) {
    this.formTarget.submit()
  }

  initialize() {
    window.addEventListener('turbolinks:load', () => {
      const select = $('.select2submit').select2({
        tags: true,
        tokenSeparators: [',', ' ']
      });
      select.on("change", function (e) {
        this.closest("form").submit();
      });
    })
  }
}
