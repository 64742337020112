
import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["selectButton"];

  selectUser(event) {
    const userId = event.currentTarget.getAttribute('value')
    document.querySelector('#assembly_protocol_nom_user_id').value = userId
    document.querySelector('#nom-user-full-name').innerText = event.currentTarget.getAttribute('name')
    this.reloadDevices(userId)
  }

  reloadDevices(userId) {
    const controller = this;

    $.ajax({
      url: `/assembly_protocols/user_unpaired_devices`,
      type: 'get',
      data: `nom_user_id=${userId}`,
      success: function (response) {
        document.querySelector('#devices-accordion').innerHTML = response;
      }
    })
  }
}
