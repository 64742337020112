import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['form', 'cancel', 'showButton', 'hideButton']

  initialize() {
    this.formTarget.classList.add('hidden')
  }

  show(e) {
    this.showButtonTarget.classList.add('hidden')
    this.formTarget.classList.remove('hidden')
  }

  hide(e) {
    this.showButtonTarget.classList.remove('hidden')
    this.formTarget.classList.add('hidden')
  }
}
