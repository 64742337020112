import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['form', 'select']

  initialize() {
    $('select').change(function(){
      console.log('baf')
    })
    //this.formTarget.classList.add('hidden')
  }

  change(e) {
    this.formTarget.submit()
  }
}
