//require("jquery-datetimepicker")
import $ from 'jquery'

import 'jquery-datetimepicker/jquery.datetimepicker.css'
import 'jquery-datetimepicker'

$.datetimepicker.setLocale('cs');
window.addEventListener('turbolinks:load', () => {
    var test = $('.datepicker').datetimepicker({
        format: 'd.m.Y H:i'
    });
})
